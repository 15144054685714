<template>
  <div >
    <HeaderView :page_index="-1" />
    <el-skeleton v-if="loading" :rows="5" animated style="text-align: left; margin: 30px 30px 30px 30px;"  />
    <div v-else class="home">
      <el-container>
      <el-container>
        <el-aside width="200px">

        </el-aside>
        <el-container>
          <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;" />
          <el-main  v-else style="text-align: left;">
            <div class="head_view">
              <div class="title"> {{info.title}}</div>
              <div class="book_view">
                <router-link :to="{ name: 'Book_general', params: { id: book_info.id }}">
                  <span>{{ book_info.name }}</span>
                </router-link>
                <span style="font-size: 30px;">·</span>
                <span>{{ info.index + 1}}</span>
              </div>
            </div>
            <div class="contents">
              <OneBookParagraph :paragraph_info="paragraph" v-for="(paragraph, index) in info.list" :key="index"  style="margin: 20px 0px;"/>
            </div>
            <div class="book_bootom">
              <div  v-if="book_info.pre.has" class="pre">
                <span>上一章</span>
                <router-link :to="{ name: 'Book_chapter', params: { id: book_info.pre.info.id }}" class="to_x">
                  <span>{{ book_info.pre.info.index + 1}}</span>
                  <span>{{ book_info.pre.info.title }}</span>
                </router-link>
              </div>
              <div v-if="book_info.next.has" class="next" >
                <span>下一章</span>
                <router-link :to="{ name: 'Book_chapter', params: { id: book_info.next.info.id }}" class="to_x">
                  <span>{{ book_info.next.info.index + 1}}</span>
                  <span>{{ book_info.next.info.title }}</span>
                </router-link>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import Utils from '@/utils/utils'
import axios from 'axios'
import OneBookParagraph from '@/components/items/OneBookParagraph'
import HeaderView from '@/components/other/HeaderView'

export default {
  name: 'Book_chapter',
  mounted () {
    this.get_index()
  },
  components: {
    HeaderView, OneBookParagraph
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      info: {},
      book_info: {}
    }
  },
  methods: {
    get_index () {
      if (localStorage.getItem('search_person_paixu')) {
        this.person_paixu = localStorage.getItem('person_paixu')
      }
      axios.post(Utils.check_is_niming() ? '/book_chapter_detail_unlogin/' : '/book_chapter_detail/', { id: this.$route.params.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
          this.book_info = res.data.data.book_info
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.head_view{
  margin: 30px 0px;
}
.head_view .title{
  color: #50b7c1;
  font-size: 32px;
  font-weight: bold;
}
.head_view .desc{
  margin: 10px 100px 10px 0px;
  font-size: 16px;
}
.contents{
  margin-right: 200px;
  font-size: 16px;
  line-height: 36px;
  text-align: left;
  padding-bottom: 50px;
}
.book_view span{
  vertical-align: middle;
  color: #aaa;
  padding-right: 4px;
}
.book_bootom {
  padding-bottom: 200px;
  margin-right: 200px;
}
.book_bootom div{
  display: inline-block;
}
.book_bootom span{
  color: #ccc;
  display: inline-block;
}
.book_bootom a span{
  color: #50b7c1;
  display: inline-block;
}
.book_bootom .next{
  float: right;
}
.book_bootom  span{
  margin-right: 5px;
}

</style>
