<template>
<div class="one_paragraph">
  <span class=""> {{info.content}}</span>
</div>
</template>
<script>

export default {
  name: 'OneBookParagraph',
  props: {
    paragraph_info: Map
  },
  data () {
    return {
      info: this.paragraph_info
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.one_book {
  color: #999;
}
.title{
  color: #50b7c1;
  font-weight: bold;
  /* line-height: 20px; */
  vertical-align: middle;

}

</style>
